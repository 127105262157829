@import url(fontawesome-all.min.css);
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  -webkit-text-size-adjust: none; }

mark {
  background-color: transparent;
  color: inherit; }

input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input, select, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none; }

/* Basic */
@-ms-viewport {
  width: device-width; }

body {
  -ms-overflow-style: scrollbar; }

@media screen and (max-width: 480px) {
  html, body {
    min-width: 320px; } }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

html, body {
  height: 100%;
  overflow-x: hidden;
  width: 100%; }
  @media screen and (max-height: 640px) {
    html, body {
      height: auto;
      min-height: 100%; } }

body {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-color: #000;
  padding: 6em 4em 4em 4em; }
  body.is-preload *, body.is-preload *:before, body.is-preload *:after {
    -moz-animation: none !important;
    -webkit-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    -moz-transition: none !important;
    -webkit-transition: none !important;
    -ms-transition: none !important;
    transition: none !important; }
  body > * {
    position: relative;
    z-index: 2; }
  @media screen and (max-width: 1680px) {
    body {
      padding: 6em 3.5em 3.5em 3.5em; } }
  @media screen and (max-width: 736px) {
    body {
      padding: 5em 2em 2em 2em; } }
  @media screen and (max-width: 360px) {
    body {
      padding: 5em 1.25em 1.25em 1.25em; } }

/* BG */
#bg {
  -moz-transition: opacity 2s ease-in-out;
  -webkit-transition: opacity 2s ease-in-out;
  -ms-transition: opacity 2s ease-in-out;
  transition: opacity 2s ease-in-out;
  height: 100%;
  left: 0;
  opacity: 0.375;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }
  #bg div {
    -moz-transition: opacity 3s ease;
    -webkit-transition: opacity 3s ease;
    -ms-transition: opacity 3s ease;
    transition: opacity 3s ease;
    background-size: cover;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 150%; }
    #bg div.visible {
      -moz-animation: bg 45s linear infinite;
      -webkit-animation: bg 45s linear infinite;
      -ms-animation: bg 45s linear infinite;
      animation: bg 45s linear infinite;
      opacity: 1;
      visibility: visible;
      z-index: 1; }
      #bg div.visible.top {
        z-index: 2; }
      @media screen and (max-width: 1280px) {
        #bg div.visible {
          -moz-animation: bg 29.25s linear infinite;
          -webkit-animation: bg 29.25s linear infinite;
          -ms-animation: bg 29.25s linear infinite;
          animation: bg 29.25s linear infinite; } }
      @media screen and (max-width: 736px) {
        #bg div.visible {
          -moz-animation: bg 18s linear infinite;
          -webkit-animation: bg 18s linear infinite;
          -ms-animation: bg 18s linear infinite;
          animation: bg 18s linear infinite; } }
    #bg div:only-child {
      -moz-animation-direction: alternate !important;
      -webkit-animation-direction: alternate !important;
      -ms-animation-direction: alternate !important;
      animation-direction: alternate !important; }
  body.is-preload #bg {
    opacity: 0; }

@-moz-keyframes bg {
  0% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -moz-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    -ms-transform: translateX(-25%);
    transform: translateX(-25%); } }

@-webkit-keyframes bg {
  0% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -moz-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    -ms-transform: translateX(-25%);
    transform: translateX(-25%); } }

@-ms-keyframes bg {
  0% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -moz-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    -ms-transform: translateX(-25%);
    transform: translateX(-25%); } }

@keyframes bg {
  0% {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -moz-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    -ms-transform: translateX(-25%);
    transform: translateX(-25%); } }

/* Type */
body, input, select, textarea {
  color: rgba(255, 255, 255, 0.75);
  font-family: "Roboto", sans-serif;
  font-size: 16pt;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1.65em; }
  @media screen and (max-width: 1680px) {
    body, input, select, textarea {
      font-size: 12pt; } }
  @media screen and (max-width: 1280px) {
    body, input, select, textarea {
      font-size: 11pt; } }
  @media screen and (max-width: 980px) {
    body, input, select, textarea {
      font-size: 12pt; } }
  @media screen and (max-width: 736px) {
    body, input, select, textarea {
      font-size: 12pt; } }
  @media screen and (max-width: 480px) {
    body, input, select, textarea {
      font-size: 12pt; } }

a {
  -moz-transition: border-bottom-color 0.2s ease, color 0.2s ease;
  -webkit-transition: border-bottom-color 0.2s ease, color 0.2s ease;
  -ms-transition: border-bottom-color 0.2s ease, color 0.2s ease;
  transition: border-bottom-color 0.2s ease, color 0.2s ease;
  border-bottom: dotted 1px rgba(255, 255, 255, 0.25);
  color: #1cb495;
  text-decoration: none; }
  a:hover {
    border-bottom-color: transparent;
    color: #fff !important;
    text-decoration: none; }

strong, b {
  color: #fff;
  font-weight: 700; }

em, i {
  font-style: italic; }

p {
  margin: 0 0 2em 0; }

h1, h2, h3, h4, h5, h6 {
  color: #ddd;
  font-weight: 700;
  line-height: 1em;
  margin: 0 0 1em 0; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
    text-decoration: none; }

h1 {
  font-size: 2.5em;
  line-height: 1.25em; }

h2 {
  font-size: 1.75em;
  line-height: 1.5em; }

h3 {
  font-size: 1.35em;
  line-height: 1.5em; }

h4 {
  font-size: 1.1em;
  line-height: 1.5em; }

h5 {
  font-size: 0.9em;
  line-height: 1.5em; }

h6 {
  font-size: 0.7em;
  line-height: 1.5em; }

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em; }

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em; }

blockquote {
  border-left: solid 8px rgba(255, 255, 255, 0.35);
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em; }

code {
  background: rgba(255, 255, 255, 0.125);
  border-radius: 6px;
  border: solid 2px rgba(255, 255, 255, 0.35);
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em; }

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0; }
  pre code {
    display: block;
    line-height: 1.75em;
    padding: 1em 1.5em;
    overflow-x: auto; }

hr {
  border: 0;
  border-bottom: solid 2px rgba(255, 255, 255, 0.35);
  margin: 2em 0; }
  hr.major {
    margin: 3em 0; }

/* Section/Article */
section.special, article.special {
  text-align: center; }

header p {
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  margin: 0 0 1.5em 0; }

header h2 + p {
  font-size: 1.25em;
  margin-top: -1em;
  line-height: 1.5em; }

header h3 + p {
  font-size: 1.1em;
  margin-top: -0.8em;
  line-height: 1.5em; }

header h4 + p,
header h5 + p,
header h6 + p {
  font-size: 0.9em;
  margin-top: -0.6em;
  line-height: 1.5em; }

@media screen and (max-width: 980px) {
  header br {
    display: none; } }

@media screen and (max-width: 736px) {
  header br {
    display: inline; } }

@media screen and (max-width: 480px) {
  header br {
    display: none; } }

/* Icon */
.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative; }
  .icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400; }
  .icon > .label {
    display: none; }
  .icon:before {
    line-height: inherit; }
  .icon.solid:before {
    font-weight: 900; }
  .icon.brands:before {
    font-family: 'Font Awesome 5 Brands'; }

/* List */
ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em; }
  ol li {
    padding-left: 0.25em; }

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em; }
  ul li {
    padding-left: 0.5em; }

/* Icons */
ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0; }
  ul.icons li {
    display: inline-block;
    padding: 0 1em 0 0; }
    ul.icons li:last-child {
      padding-right: 0; }
    ul.icons li .icon:before {
      font-size: 1.25em; }
    ul.icons li a {
      color: inherit; }

/* Form */
form {
  margin: 0 0 2em 0; }
  form .message {
    text-decoration: none;
    -moz-transition: opacity 0.2s ease-in-out, -moz-transform 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out, -ms-transform 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    height: 2.75em;
    line-height: 2.75em;
    opacity: 0; }
    form .message:before {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      text-transform: none !important;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900; }
    form .message:before {
      margin-right: 0.5em; }
    form .message.visible {
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      opacity: 1; }
    form .message.success {
      color: #1cb495; }
      form .message.success:before {
        content: '\f00c'; }
    form .message.failure {
      color: #ff2361; }
      form .message.failure:before {
        content: '\f119'; }

label {
  color: #fff;
  display: block;
  font-size: 0.9em;
  font-weight: 700;
  margin: 0 0 1em 0; }

@-moz-keyframes focus {
  0% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  50% {
    -moz-transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -ms-transform: scale(1.025);
    transform: scale(1.025); }
  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes focus {
  0% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  50% {
    -moz-transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -ms-transform: scale(1.025);
    transform: scale(1.025); }
  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); } }

@-ms-keyframes focus {
  0% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  50% {
    -moz-transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -ms-transform: scale(1.025);
    transform: scale(1.025); }
  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); } }

@keyframes focus {
  0% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  50% {
    -moz-transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -ms-transform: scale(1.025);
    transform: scale(1.025); }
  100% {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); } }

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -moz-transition: border-color 0.2s ease, background-color 0.2s ease;
  -webkit-transition: border-color 0.2s ease, background-color 0.2s ease;
  -ms-transition: border-color 0.2s ease, background-color 0.2s ease;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  background-color: transparent;
  border-radius: 6px;
  border: none;
  border: solid 2px rgba(255, 255, 255, 0.35);
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%; }
  input[type="text"]:invalid,
  input[type="password"]:invalid,
  input[type="email"]:invalid,
  select:invalid,
  textarea:invalid {
    box-shadow: none; }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  select:focus,
  textarea:focus {
    -moz-animation: focus 0.1s;
    -webkit-animation: focus 0.1s;
    -ms-animation: focus 0.1s;
    animation: focus 0.1s;
    background-color: rgba(255, 255, 255, 0.125);
    border-color: #1cb495; }

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.35)' /%3E%3C/svg%3E");
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  height: 2.75em;
  padding-right: 2.75em;
  text-overflow: ellipsis; }
  select option {
    color: #fff;
    background: #000; }
  select:focus::-ms-value {
    background-color: transparent; }
  select::-ms-expand {
    display: none; }

input[type="text"],
input[type="password"],
input[type="email"],
select {
  height: 2.75em; }

textarea {
  padding: 0.75em 1em; }

input[type="checkbox"],
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1; }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
    padding-left: 2.4em;
    padding-right: 0.75em;
    position: relative; }
    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      text-transform: none !important;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900; }
    input[type="checkbox"] + label:before,
    input[type="radio"] + label:before {
      background: rgba(255, 255, 255, 0.125);
      border-radius: 6px;
      border: solid 2px rgba(255, 255, 255, 0.35);
      content: '';
      display: inline-block;
      font-size: 0.8em;
      height: 1.65em;
      left: 0;
      line-height: 1.65em;
      position: absolute;
      text-align: center;
      top: 0;
      width: 1.65em; }
  input[type="checkbox"]:checked + label:before,
  input[type="radio"]:checked + label:before {
    background: #1cb495;
    border-color: #1cb495;
    color: #ffffff;
    content: '\f00c'; }
  input[type="checkbox"]:focus + label:before,
  input[type="radio"]:focus + label:before {
    border-color: #1cb495;
    box-shadow: 0 0 0 2px #1cb495; }

input[type="checkbox"] + label:before {
  border-radius: 6px; }

input[type="radio"] + label:before {
  border-radius: 100%; }

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1.0; }

:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1.0; }

::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1.0; }

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1.0; }

.formerize-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1.0; }

/* Button */
input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  background-color: #fff;
  border-radius: 6px;
  border: 0;
  color: #333 !important;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  height: 2.75em;
  line-height: 2.75em;
  padding: 0 1.125em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap; }
  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover,
  button:hover,
  .button:hover {
    background-color: #1fcaa7; }
  input[type="submit"]:active,
  input[type="reset"]:active,
  input[type="button"]:active,
  button:active,
  .button:active {
    background-color: #199e83; }
  input[type="submit"].disabled, input[type="submit"]:disabled,
  input[type="reset"].disabled,
  input[type="reset"]:disabled,
  input[type="button"].disabled,
  input[type="button"]:disabled,
  button.disabled,
  button:disabled,
  .button.disabled,
  .button:disabled {
    opacity: 0.5; }
  @media screen and (max-width: 480px) {
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
      padding: 0; } }

/* Header */
#header h1 {
  font-size: 3.25em;
  margin: 0 0 0.55em 0; }

#header p {
  font-size: 1.35em;
  line-height: 1.65em; }

#header a {
  color: inherit; }

@media screen and (max-width: 736px) {
  #header h1 {
    font-size: 2em; }
  #header p {
    font-size: 1em; } }

.language-btn {
  margin: 0;
  padding: 10px;
  background-color: transparent;
  color: white !important;
  opacity: 0.6;
}
.language-btn:hover {
  background-color: transparent;
  opacity: 0.8;
  background-opacity: 0;
}
.language-btn:active {
  background-color: transparent;
  opacity: 1.0;
  background-opacity: 0;
}

@media screen and (max-width: 480px) {
  #header {
    margin: 0 0 1em 0; } }

/* Signup Form */
#signup-form {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  position: relative; }
  #signup-form input[type="text"],
  #signup-form input[type="password"],
  #signup-form input[type="email"] {
    width: 18em; }
  #signup-form > * {
    margin: 0 0 0 1em; }
  #signup-form > :first-child {
    margin: 0 0 0 0; }
  @media screen and (max-width: 480px) {
    #signup-form {
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      #signup-form input[type="type"],
      #signup-form input[type="password"],
      #signup-form input[type="email"] {
        width: 100%; }
      #signup-form > * {
        margin: 1.25em 0 0 0; }
      #signup-form .message {
        bottom: -1.5em;
        font-size: 0.9em;
        height: 1em;
        left: 0;
        line-height: inherit;
        margin-top: 0;
        position: absolute; } }

#footer {
  -moz-transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  bottom: 4em;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
  position: fixed; }
  #footer .icons {
    margin: 0 0 0.5em 0; }
  #footer .copyright {
    font-size: 0.8em;
    list-style: none;
    padding: 0; }
    #footer .copyright li {
      border-left: solid 1px rgba(255, 255, 255, 0.25);
      display: inline-block;
      line-height: 1em;
      margin: 0 0 0 0.75em;
      padding: 0 0 0 0.75em; }
      #footer .copyright li:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0; }
    #footer .copyright a {
      color: inherit; }
  #footer:hover {
    opacity: 1; }
  #footer > :last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 1680px) {
    #footer {
      bottom: 3.5em;
      left: 3.5em; } }
  @media screen and (max-width: 736px) {
    #footer {
      bottom: 2em;
      left: 2em; } }
  @media screen and (max-width: 360px) {
    #footer {
      bottom: 1.25em;
      left: 1.25em; } }
  @media screen and (max-height: 640px) {
    #footer {
      bottom: auto;
      left: auto;
      margin: 1em 0 0 0;
      position: relative; } }
